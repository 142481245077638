import React from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"
import { parseISO, format } from 'date-fns'
import ja from 'date-fns/locale/ja'

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import PublicContributionTab from "../../components/parts/public-contribution-tab"

import SortTable from "../../components/parts/sort-table"
import { DateSort } from "../../utils/table-sort"

// markup
const IndexPage = ({data}) => {
  const markdownRemark = data.markdownRemark
  const publicContributionSurvey = data.publicContributionSurvey.edges

  const { frontmatter } = markdownRemark
  const { title, description, slug } = frontmatter

  const tableHeader = [
    { Header: '事業名', accessor: 'title', disableSortBy: true },
    { Header: '予告掲載日', accessor: 'noticedAt', sortType: DateSort },
    { Header: '公募開始日', accessor: 'startedAt', sortType: DateSort },
    { Header: '採択結果', accessor: 'adoptedAt', sortType: DateSort },
  ]

  const tableRows = publicContributionSurvey.map(item => {
    return {
      title: (
        <span dangerouslySetInnerHTML={{ __html: specialCharacterReplace(item.node.title) }} />
      ),
      noticedAt: (
        <>
          {
          item.node.noticedAt !== null && item.node.noticedAt.date ?
                item.node.noticedAt.link ?
                  <a href={item.node.noticedAt.link} target="_blank" rel="noreferrer noopener">
                    {format(parseISO(item.node.noticedAt.date), 'yyyy年MM月dd日', {locale: ja})}
                  </a> :
                  format(parseISO(item.node.noticedAt.date), 'yyyy年MM月dd日', {locale: ja}) :
                  '-'
          }
        </>
      ),
      startedAt: (
        <>
        {
          item.node.startedAt !== null && item.node.startedAt.date ? 
            item.node.startedAt.link ?
              <a href={item.node.startedAt.link} target="_blank" rel="noreferrer noopener">
                {format(parseISO(item.node.startedAt.date), 'yyyy年MM月dd日', {locale: ja})}
              </a> :
              format(parseISO(item.node.startedAt.date), 'yyyy年MM月dd日', {locale: ja}) :
              '-'
        }
        </>
      ),
      adoptedAt: (
        <>
          { 
          item.node.adoptedAt !== null && item.node.adoptedAt.date ? 
            item.node.adoptedAt.link ? 
              <a href={item.node.adoptedAt.link} target="_blank" rel="noreferrer noopener">
                {format(parseISO(item.node.adoptedAt.date), 'yyyy年MM月dd日', {locale: ja})}
              </a> :
              format(parseISO(item.node.adoptedAt.date), 'yyyy年MM月dd日', {locale: ja}) :
              '-'
          }
        </>
      ),
    }
  })

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        path={slug}
      />
      <BreadCrumb
        parentLink="/about/"
        parentTitle="グリーンイノベーション基金事業概要等"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading}>{title}</h1>
      </div>
      <div className={styles.leadText}>
      </div>

      <main className={styles.pageContents}>
        <PublicContributionTab current="public-contribution-survey" />

        <div className={styles.tableWrapper}>
          <SortTable exClass={`${styles.basicTable} ${styles.publicContribution} ${styles.publicContributionSurvey}`} tableHeader={tableHeader} tableRows={tableRows} summary="公募一覧(調査)" />
        </div>
      </main>
      
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
    publicContributionSurvey: allMicrocmsPublicContributionSurvey {
      edges {
        node {
          title
          startedAt {
            date
            link
          }
          noticedAt {
            date
            link
          }
          publishedAt
          adoptedAt {
            link
            date
          }
        }
      }
    }
  }
`